<template>
    <div class="page-content">
        <div class="container-fluid">
            <!-- start page title -->
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0 font-size-18">Quản lý Logs Worker</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item">
                                    <a href="javascript: void(0);">Admin Traodoicheo</a>
                                </li>
                                <li class="breadcrumb-item active">Quản lý Logs Worker</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end page title -->
            <!-- start page filter -->
            <div class="row">
                <div class="col-12">
                    <div class="card py-4">
                        <div class="container-fluid">
                            <div class="row">
                                <form @submit.prevent="getLogsList" class="col-md-4 mb-4">
                                    <div class="form-group">
                                        <label for="">Tìm theo Username</label>
                                        <input type="text" class="form-control" placeholder="nhập username "
                                            v-model="username" />
                                    </div>
                                </form>
                                <form @submit.prevent="getLogsList" class="col-md-4 mb-4">
                                    <div class="form-group">
                                        <label for="">Tìm theo Object Id</label>
                                        <input type="text" class="form-control" placeholder="nhập Object Id"
                                            v-model="object_id" />
                                    </div>
                                </form>
                                <form @submit.prevent="getLogsList" class="col-md-4 mb-4">
                                    <div class="form-group">
                                        <label for="">Tìm theo FB Id</label>
                                        <input type="text" class="form-control" placeholder="nhập FB Id"
                                            v-model="fb_id" />
                                    </div>
                                </form>
                                <form class="col-md-4 mb-4">
                                    <div class="form-group">
                                        <label for="">Log type</label>
                                        <select class="form-select" v-model="log_type" @change="getLogsList">
                                            <option value="">Tất cả</option>
                                            <option value="success">Đã duyệt</option>
                                            <option value="pending">Chờ duyệt</option>
                                            <option value="error">Làm lỗi</option>
                                            <option value="removed">Xóa</option>
                                        </select>
                                    </div>
                                </form>
                                <form class="col-md-4 mb-4">
                                    <div class="form-group">
                                        <label for="">Limit</label>
                                        <select class="form-select" v-model="limit" @change="getLogsList">
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                            <option value="1000">1000</option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                            <div class="row mt-3" v-if="showUserToken">
                                <div class="col-12 mb-4 mb-md-0">
                                    <div>
                                        <label for="" class="text-danger fw-bold"> User token (click để copy) </label>
                                        <div @click="copyText(userToken)">
                                            <input type="text" v-model="userToken" class="form-control" disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end page filter -->
            <loading-vue v-if="loading" />
            <div class="row">
                <div class="col-md-12">
                    <datatable :stt="true" :title="'Danh sách Logs Worker'" :columns="columns" :rows="manipulatedLogs"
                        :defaultPerPage="10">
                        <th slot="thead-tr-o" class="text-center" style="vertical-align: middle">Thao Tác</th>
                        <td slot="tbody-tr-o" class="text-center">
                            <button class="btn btn-success font-10 py-1 btn-xs">Action</button>
                        </td>
                    </datatable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-undef */
import logsWorkerApi from "@/api/logs-worker";
import userApi from "@/api/user";
import Datatable from "@/components/datatable/Datatable.vue";
import LoadingVue from "@/components/loading/Loading.vue";
import { buildParamQuery, catchError } from "@/helpers/index";

export default {
    name: "LogsWorker",
    components: {
        Datatable,
        LoadingVue,
    },
    data() {
        return {
            username: null,
            object_id: null,
            fb_id: null,
            log_type: "",
            limit: 50,
            logs: [],
            loading: false,
            showUserToken: false,
            userToken: null,
            columns: [
                { label: "Username", field: "username" },
                { label: "FB name", field: "fb_name" },
                { label: "FB Id", field: "fb_id", fb_link: true },
                { label: "Job Id", field: "job_id" },
                { label: "Object Id", field: "object_id", fb_link: true },
                { label: "User Id", field: "user_id" },
                { label: "Coin", field: "coin", numeric: true },
                { label: "Package Name", field: "package_name" },
                { label: "Type", field: "type" },
                { label: "Ngày thanh toán", field: "paid_at", timeago: true },
                { label: "Ngày tạo", field: "created_at", timeago: true },
                { label: "Ngày cập nhật", field: "updated_at", timeago: true },
            ],
        };
    },
    computed: {
        manipulatedLogs() {
            return this.logs.map((log) => {
                log.fb_name = log.account ? log.account.facebook_name : "";
                return log;
            });
        },
    },
    created() {
        this.getLogsList();
    },
    methods: {
        async getLogsList() {
            const params = {
                limit: this.limit,
                username: this.username,
                object_id: this.object_id,
                fb_id: this.fb_id,
                log_type: this.log_type,
            };
            const queries = buildParamQuery(params);
            this.loading = true;
            const response = await logsWorkerApi.list(queries);
            if (params.username && params.username !== "") {
                this.getUserToken();
            } else {
                this.showUserToken = false;
            }
            this.loading = false;
            if (response?.success) {
                this.logs = response?.data || [];
            } else {
                this.$toastr.error(catchError(response));
            }
        },
        async getUserToken() {
            const params = {
                username: this.username,
            };
            const response = await userApi.getToken(params);
            if (response?.success) {
                this.userToken = response?.token;
                this.showUserToken = true;
            } else {
                this.showUserToken = false;
                this.$toastr.error(catchError(response));
            }
        },
        copyText(value) {
            if (value && value !== "") {
                this.$copyText(value)
                    .then(() => {
                        this.$toastr.success("Copy thành công !");
                    })
                    .catch(() => {
                        this.$toastr.error("Copy thất bại !");
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
