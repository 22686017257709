import { requestApi } from "./index";

const api = {
  list: async (params = "") => requestApi("/admin2021/users" + params),
  update: async (params) => requestApi("/admin2021/users/update", params),
  resetPassword: async (params) => requestApi("/admin2021/users/reset-pass", params),
  updateCoin: async (params) => requestApi("/admin2021/users/update-coin", params),
  getToken: async (params) => requestApi("/admin2021/users/get-token", params),
  block: async (params) => requestApi("/admin2021/users/blacklists/block", params),
  unblock: async (params) => requestApi("/admin2021/users/blacklists/open", params),
  listBlock: async (params) => requestApi("/admin2021/users/blacklists", params),
};

export default api;
